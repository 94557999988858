import React from 'react'

const CentHand: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5 15C23.4999 5.39167 30.6667 2.66667 34.5 2.5C39.5001 2.5 45.9999 5.5 46.4999 15C46.8999 22.6 40.1665 25.5 35.9999 26C32.4999 26.3333 23.5001 22.5 23.5 15Z"
        fill="white"
      />
      <path
        d="M2 44V28H7.5L17 32.5L27.5 33.5L32 37H40L47.5 38L51 44L21 53.5L2 44Z"
        fill="#D1FFDB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0003 3.73311C29.3301 3.73311 24.7336 8.32965 24.7336 13.9998C24.7336 19.6699 29.3301 24.2664 35.0003 24.2664C40.6704 24.2664 45.2669 19.6699 45.2669 13.9998C45.2669 8.32965 40.6704 3.73311 35.0003 3.73311ZM21.9336 13.9998C21.9336 6.78325 27.7837 0.933105 35.0003 0.933105C42.2168 0.933105 48.0669 6.78325 48.0669 13.9998C48.0669 21.2163 42.2168 27.0664 35.0003 27.0664C27.7837 27.0664 21.9336 21.2163 21.9336 13.9998Z"
        fill="#00CC2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.933594 26.6001H4.66693C6.09373 26.6001 8.51327 26.8759 10.9275 27.6304C13.0827 28.304 15.4457 29.4245 17.0178 31.2668H23.3336C26.1802 31.2668 28.9103 32.3976 30.9232 34.4105C32.9361 36.4234 34.0669 39.1534 34.0669 42.0001V43.4001H17.2669V40.6001H31.1425C30.8585 39.0161 30.0969 37.5439 28.9433 36.3904C27.4555 34.9026 25.4376 34.0668 23.3336 34.0668H15.6049L15.1869 33.4699C14.1763 32.0271 12.2641 30.9817 10.0923 30.3029C7.95591 29.6353 5.81846 29.4001 4.66693 29.4001H3.73359V43.4681L18.2826 50.7415C20.1071 51.6542 22.2132 51.8291 24.1632 51.2297L49.5527 43.4167L49.2682 42.562C48.8963 41.4473 48.1831 40.4773 47.2298 39.7903C46.2763 39.1031 45.1309 38.7334 43.9556 38.7334H38.2669V35.9334H43.9556C45.7184 35.9334 47.4367 36.488 48.8668 37.5187C50.297 38.5494 51.3667 40.0039 51.9244 41.6762L53.1144 45.2502L24.9867 53.9058C22.3485 54.7169 19.499 54.4807 17.0306 53.246L0.933594 45.1987V26.6001Z"
        fill="#00CC2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3996 10.2661V17.7328H33.5996V10.2661H36.3996Z"
        fill="#00CC2C"
      />
    </svg>
  )
}

export default CentHand
